import React from "react";
import { Redirect, Switch, Route, Router} from "react-router-dom";
import RouteGuard from "./components/RouteGuard"
 
//history
import { history } from './helpers/history';
 
//pages
import Label from './pages/Label.js';
import Login from "./pages/Login.js"
import Projects from "./pages/Projects.js"

// TODO
// read on logiign in JWT and routingg!
// create redirect if click on project page --> then input project IRI as input for route: label?
// if clikc on logut, change the login indicator to flase and reerender the navbar item

// Switch to be created bbox
const labelType = 'bbox'

// const startProject = (iri) => {
//     console.log('iri',iri)
//     var iri = iri;
//     window.location.href = "/label"  
//   }

function Routes() {
   return (
        <Router history={history}>
           <Switch>
            {/* Also a route */}
            {/*  route in switch, new version, new syntax with Component wrapped in route:  <Route> <Component /> </Route> */}
               <RouteGuard
                   exact
                   path="/label"
                   component={Label}
               />
               <RouteGuard
                   exact
                   path="/projects"
                   component={Projects}
               />
               <Route
                   path="/login"
                   component={Login}
               />
               <Redirect to="/projects" />
           </Switch>
       </Router>
   );
}
 
export default Routes